import React from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/forms/Button';
import Popup from 'components/popup/Popup';
import { usePopup } from 'context/PopupProvider';
import { AnchorLink } from 'components/Link';

const Wrap = styled.span`
	margin-top: 0 !important;
	${p =>
		p.saved &&
		css`
			text-align: center;
			display: block;
			margin-bottom: 50px;
		`}
	> button {
		line-height: 29px;
		font-weight: 500;
		display: inline-block;
		margin-left: 10px;
	}
`;

const TextLink = styled(AnchorLink)`
	text-decoration: underline;
`;

/**
 * Popup error component
 * @param {Object} props
 * @param {String} props.title - The title of the popup
 * @param {String} props.text - The text of the popup
 * @param {String} props.id - The id of the popup
 * @param {String} props.entry - The entry to resolve
 * @param {Function} props.resolver - The function to resolve the entry
 * @param {String} props.errorMessage - The error message
 * @param {String} props.type - The type of button to render
 * @param {JSX.Element} props.children - The children of the popup
 * @returns {JSX.Element}
 */
export default function PopupError({
	title = 'Ta kontakt med oss',
	text = '',
	id = 'error-popup',
	entry,
	resolver,
	errorMessage,
	type = 'button',
	form,
	...rest
}) {
	const { activePopup, setActivePopup } = usePopup();

	return (
		<Wrap {...rest}>
			{text && `${text} `}
			<ButtonTriggerComponent
				type={type}
				title={rest?.buttonTitle}
				aria-haspopup={true}
				aria-controls={id}
				aria-expanded={activePopup === id}
				onClick={() => {
					setActivePopup(id);
				}}
			/>

			<Popup id={id} title={title} size="medium">
				{form}
			</Popup>
		</Wrap>
	);
}

/**
 * Button trigger component
 * @param {Object} props
 * @param {String} props.type - The type of button to render
 * @param {String} props.title - The title of the button
 * @param {String} props.id - The id of the button
 * @returns {JSX.Element}
 */
export function ButtonTriggerComponent({
	type = 'button',
	title = 'Ta kontakt med oss',
	id = 'error-popup',
}) {
	const { activePopup, setActivePopup } = usePopup();

	// Determine which component to use
	const Component = type === 'text' ? TextLink : Button;

	return (
		<Component
			title={type === 'text' ? `${title}.` : 'Les mer'}
			aria-haspopup={true}
			aria-controls={id}
			aria-expanded={activePopup === id}
			onClick={() => {
				setActivePopup(id);
			}}>
			{type === 'text' ? `${title}.` : 'Les mer'}
		</Component>
	);
}
